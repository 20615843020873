<template>
    <div class="editor">
        <div class="button-box">
            <el-button type="info" @click="saveDraft">保存草稿</el-button>
            <el-button type="info" @click="checkDialog">导出到库</el-button>
            <el-button type="primary" @click="publishVideo">立即投稿</el-button>
        </div>
        <div id="container"></div>
        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="30%">
            <span v-if="Number(process) < 100 || status === 'PROCESSING'">导出中{{process}}%,状态{{status}}</span>
            <span v-else>导出成功，请点击<a :href="url" target="_blank">这里</a>查看结果</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <div class="library-box">
            <el-dialog
                    title="视频合成"
                    :visible.sync="dialogVisible1"
                    width="70%"
                    :before-close="handleClose">
                <div class="d-mainBox">
                    <div class="left-box">
                        <video width="90%" height="100%" controls>
                            <source :src="video_file" type='video/mp4'>
                        </video>
                    </div>
                    <div class="right-box">
                        <div class="row1">
                            <span>名称：</span>
                            <span style="width: 90%">
                            <el-input v-model="projectName" size="small" placeholder="请输入名称"></el-input>
                        </span>
                        </div>
                        <div class="row1">
                            <span>封面：</span>
                            <div class="img-box">
                                <img :src="screenshot" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveLibrary">合 成</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VideoEditor",
        data() {
            return {
                Name: "",
                AspectRatio: "",
                sign: "",
                userId: "",
                Category: "VIDEO_EDIT",
                uploadSign: "",
                projectId: "",
                taskId: "",
                dialogVisible: false,
                dialogVisible1: false,
                url: "",
                process: "",
                status: "",
                newPic: "",
                video_file: "",
                file_path: '',
                exportTime: 0,//连续导出会有bug，限制连续导出
                projectName: "",//保存到库的名称
                screenshot: "",//保存截取的图片
                vodFileId: "",
            }
        },
        mounted() {
            this.getParam();
        },
        methods: {
            getParam() {
                this.sign = this.$route.query.sign;
                this.projectId = this.$route.query.projectId;
                this.userId = localStorage.getItem("username");
                this.Name = this.$route.query.title;
                this.AspectRatio = this.$route.query.size;
                this.getContain();
            },
            getContain() {
                /* @create  创建实例方法,没调用该方法之前,调用 send 方法通信无效。
                *    @param el {{HTMLELement}}  html元素,作为父容器承载 CME 组件。
                *    @param options {{ object|hash }} 参数。
                *    @param sign {{ string }} ，该项目签名，具体参见 签名算法。
                *    @return 返回一个 cme 组件实例。
                **/
                let myCmeInstance = CME.create(document.getElementById("container"), {
                    sign: this.sign,
                });

                /**
                 * 页面初始化异常时，会抛出Error事件。
                 **/
                myCmeInstance.on("Error", (data) => {
                    console.log("Error", data);
                });

                /**
                 * 页面组件完全准备好以后，会抛出：Editor:Ready 事件，
                 * 该事件触发以后，所有命令字会正常响应，触发之前，部分命令字无法响应。
                 **/
                myCmeInstance.on("Editor:Ready", () => {
                    console.log("desc", "iframe Ready");
                    /**
                     *
                     * @send 发送命令字方法,
                     *    @param cmd {{string}} 字符串,参考 cme 命令字列表。
                     *    @param param {{object}} 命令入参。
                     *    @param callback {{function}} 完成命令的回调函数。
                     *
                     **/
                    myCmeInstance.send("syncFusionData", {}, (data) => {
                        console.log("sync done", data);
                    });
                });
                myCmeInstance.on("Editor:UploadBtn:Click", () => {
                    let sign = this.applyUploadSign();
                    myCmeInstance.send("showUpload", {sign: sign}, (data) => {
                    });
                });
                myCmeInstance.on("Header:BackBtn:Click", () => {
                    this.$router.push('/student/video/clip');
                });
                myCmeInstance.on('Editor:MoreResourceBtn:Click', () => {
                    console.log(111)
                });
                myCmeInstance.on('Editor:ExportBtn:Click', () => {
                    this.exportCloud();
                })

                let clickHandler = () => {
                    console.log("Click");
                };
                /**
                 *
                 * @on 监听方法,监听来自 CME 的用户事件。
                 *    @param eventName {{string}} 字符串,参考 cme 自定义用户事件。
                 *    @param callback {{function}} 回调函数。
                 *
                 */
                myCmeInstance.on("Editor:MoreResourceBtn:click", clickHandler);

                /**
                 * @off 取消监听方法,监听来自 CME 的事件,如果什么都不传则会清理所有监听事件。
                 *    @param eventName {{string}} 字符串,参考 cme 自定义用户事件。
                 *    @param callback {{function}} 对应回调函数。
                 *
                 */
                myCmeInstance.off("Editor:MoreResourceBtn:click", clickHandler);
            },
            //申请上传签名，官网链接[]
            applyUploadSign(subAppId = 0) {
                let current = parseInt((new Date()).getTime() / 1000)
                let expired = current + 86400  // 签名有效期：1天

                let args = {
                    secretId: 'AKID1r5fPTcn4QgjhVRVfOjR7dlKCnQKs3hB',
                    currentTimeStamp: current,
                    expireTime: expired,
                    random: Math.round(Math.random() * Math.pow(2, 32)),
                    platform: 'yunjian',
                    action: "CreateUploadMaterial",
                    userId: '100018186395'
                }

                return this.genSha1Sign(args)
            },
            genSha1Sign(args) {
                var querystring = require("querystring");
                var crypto = require('crypto');

                let original = querystring.stringify(args)
                let originalBuffer = Buffer.from(original, "utf8")
                let hmac = crypto.createHmac("sha1", 'RgiU9f1JnfNuIcHPrDZXHBqmWXKq512V')
                let hmacBuffer = hmac.update(originalBuffer).digest()
                let signature = Buffer.concat([hmacBuffer, originalBuffer]).toString("base64")
                return signature
            },
            getSign() {
                let param = {
                    name: this.Name,
                    aspectRatio: this.AspectRatio,
                    action: 2,//1创建，2上传
                }
                this.$httpStudent.axiosPost(this.$api.createSign, param, res => {
                    if (res.code == 200) {
                        // this.uploadSign = res.data.signature;
                        return res;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            exportCloud() {
                if (this.exportTime > 0) {
                    return
                }
                let param = {
                    projectId: this.projectId,
                    definition: 11,//10：分辨率为 480P，输出视频格式为 MP4；11：分辨率为 720P，输出视频格式为 MP4；12：分辨率为 1080P，输出视频格式为 MP4
                    exportDestination: 2,//1：云剪，即导出为云剪素材；VOD：云点播，即导出为云点播媒资
                }
                this.$httpStudent.axiosPost(this.$api.exportCloudVideo, param, res => {
                    this.taskId = res.TaskId;
                    this.getDetail();
                })
                this.exportTime = this.exportTime + 1;
            },
            getDetail() {
                this.dialogVisible = true;
                this.$httpStuNoLoading.axiosPost(this.$api.describeTaskDetail, {taskId: this.taskId}, res => {
                    this.process = res.Progress;
                    this.status = res.Status;
                    if (Number(res.Progress) < 100 || res.Status === 'PROCESSING') {
                        setTimeout(() => {
                            this.getDetail()
                        }, 2 * 1000)
                    } else {
                        if (res.VideoEditProjectOutput.URL && res.VideoEditProjectOutput.URL != '') {
                            this.url = res.VideoEditProjectOutput.URL;
                            this.vodFileId = res.VideoEditProjectOutput.VodFileId;
                            this.downloadVideo(this.url);
                            this.importToVod(this.vodFileId);
                        }
                    }
                })
            },
            downloadVideo(val) {
                this.$httpStudent.axiosPost(this.$api.downloadVideo, {url: val}, res => {
                    if (res.code == 200) {
                        this.video_file = res.data.url;
                        this.file_path = res.data.src;
                        this.getVideoBase64(res.data.url);
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            importToVod(val) {
                let param = {
                    projectId: this.projectId,
                    vodFileId: val,
                }
                this.$httpStudent.axiosPost(this.$api.importVideoProject, param, res => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            getVideoBase64(url) {
                let that = this;
                let data = new Promise(function (resolve, reject) {
                    let dataURL = '';
                    let video = document.createElement("video");
                    video.setAttribute('crossOrigin', 'anonymous');//处理跨域
                    video.setAttribute('useCORS', true);//处理跨域
                    video.setAttribute('src', url);
                    video.setAttribute('width', 400);
                    video.setAttribute('height', 240);
                    video.setAttribute('autoplay', 'autoplay');
                    video.addEventListener('loadeddata', function () {
                        let canvas = document.createElement("canvas"),
                            width = video.width, //canvas的尺寸和图片一样
                            height = video.height;
                        canvas.width = width;
                        canvas.height = height;
                        canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
                        dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
                        resolve(dataURL);
                    });
                });
                data.then(function (d) {
                    that.screenshot = d;
                    that.dataToFile(d)
                })
            },
            dataToFile(dataURL) {
                let bytes = window.atob(dataURL.split(',')[1]); //去掉url的头，并转换为byte
                //处理异常,将ascii码小于0的转换为大于0
                let ab = new ArrayBuffer(bytes.length);
                let ia = new Uint8Array(ab);
                for (var i = 0; i < bytes.length; i++) {
                    ia[i] = bytes.charCodeAt(i);
                }
                this.newPic = new File([ab], 'test.jpg', {
                    type: 'image/jpeg'
                });
            },
            handleClose() {
                this.dialogVisible1 = false;
                this.projectName = "";
            },
            saveVideo() {

            },
            saveDraft() {
                let id = this.$route.query.id;
                if (this.newPic == "") {
                    this.$message.warning("请先导出视频！")
                } else {
                    let formData = new FormData();
                    formData.append('title', this.Name);
                    formData.append('size', this.AspectRatio);
                    formData.append('video_file', this.video_file);
                    formData.append('video_cover', this.newPic);
                    formData.append('status', 2);
                    formData.append('project_id', this.projectId);
                    if (id) {
                        formData.append('id', id);
                    }
                    this.$httpStudent.axiosPost(this.$api.saveVideo, formData, res => {
                        if (res.code == 200) {
                            this.$message.success(res.message);
                            this.$router.push({
                                path: '/student/video/clip',
                                query: {
                                    ShortTab: "first"
                                }
                            });
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }
            },
            checkDialog() {
                if (this.newPic == "") {
                    this.$message.warning("请先导出视频！")
                } else {
                    this.dialogVisible1 = true
                }
            },
            saveLibrary() {
                let id = this.$route.query.id;
                if (this.projectName == '') {
                    this.$message.warning("请输入名称")
                    return
                }
                let formData = new FormData();
                formData.append('title', this.projectName);
                formData.append('size', this.AspectRatio);
                formData.append('video_file', this.video_file);
                formData.append('video_cover', this.newPic);
                formData.append('status', 1);
                formData.append('project_id', this.projectId);
                if (id) {
                    formData.append('id', id);
                }
                this.$httpStudent.axiosPost(this.$api.saveVideo, formData, res => {
                    if (res.code == 200) {
                        this.$message.success(res.message);
                        this.$router.push({
                            path: '/student/video/clip',
                            query: {
                                ShortTab: "second"
                            }
                        });
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            publishVideo() {
                if (this.newPic == "") {
                    this.$message.warning("请先导出视频！")
                } else {
                    let id = this.$route.query.id;
                    let formData = new FormData();
                    formData.append('title', this.Name);
                    formData.append('size', this.AspectRatio);
                    formData.append('video_file', this.video_file);
                    formData.append('video_cover', this.newPic);
                    formData.append('status', 1);
                    formData.append('project_id', this.projectId);
                    if (id) {
                        formData.append('id', id);
                    }
                    this.$httpStudent.axiosPost(this.$api.saveVideo, formData, res => {
                        if (res.code == 200) {
                            this.$message.success(res.message);
                            this.queryLibrary();
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }
            },
            queryLibrary() {
                let param = {
                    status: 1,
                    title: this.Name,
                    page: 1,
                    limit: 1,
                }
                this.$httpStudent.axiosGetBy(this.$api.videoList, param, res => {
                    if (res.code == 200) {
                        this.$router.push({
                            path: '/student/video/publish',
                            query: {
                                file_path: res.data.data[0].video_file.src,
                                file_path_url: res.data.data[0].video_file.url,
                                vId: res.data.data[0].id
                            }
                        });
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .button-box .el-button {
        width: 94px;
        height: 30px;
        line-height: 0;
        border-radius: 15px;
    }

    .editor {
        height: 100vh;
        position: relative;

        .button-box {
            position: absolute;
            right: 40px;
            padding-top: 5px;
        }


        #container {
            background: #ccc;
            height: 100%;
            width: 100%;
            border: none;
        }

        .library-box {
            ::v-deep .el-dialog__footer {
                text-align: center;
                background: #211D40;
            }

            ::v-deep .el-dialog__header {
                background: #120E2D;
            }

            ::v-deep .el-dialog__title {
                color: #ffffff;
                font-size: 18px;
            }

            ::v-deep .el-dialog__body {
                background: #211D40;
            }

            ::v-deep .el-dialog__footer .el-button {
                width: 90px;
                height: 30px;
                line-height: 0;
                border-radius: 15px;
            }

            .d-mainBox {
                display: flex;
                flex-direction: row;

                .left-box {
                    min-width: 50%;
                }

                .right-box {
                    min-width: 40%;

                    .row1 {
                        display: flex;
                        min-height: 90px;
                        width: 100%;

                        ::v-deep span .el-input__inner {
                            background: #211D40;
                            border-top-width: 0px;
                            border-left-width: 0px;
                            border-right-width: 0px;
                            border-bottom-width: 1px;
                        }

                        .img-box {
                            width: 100%;
                            height: 300px;

                            img {
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
</style>